import { Link } from "gatsby";
import React from "react";
import * as styles from "./style.module.scss";
import taiken from "/static/parts_taikenmoushikomi.svg";
import toiawase from "/static/parts_toiawase.svg";

const FormLinkButtons = ({ unfixed, onClick }) => {
	return (
		<div className={[styles.tabButtonWrapper, unfixed && styles.buttonWrapper].join(" ")}>
			<Link to="/contact/" className={[styles.tabButton, styles.yellow, unfixed && styles.button].join(" ")} onClick={onClick}>
				<img className={styles.icon} src={toiawase} width="31" height="31" alt=""/>
				<span>お問い合わせ</span>
			</Link>
			<Link to="/contact/trial-lesson/" className={[styles.tabButton, styles.orange, unfixed && styles.button].join(" ")} onClick={onClick}>
				<img className={styles.icon} src={taiken} width="31" height="31" alt=""/>
				<span>体験レッスン<br className={styles.sp}/>申込</span>
			</Link>
		</div>
	);
};

export default FormLinkButtons;