import { Link } from "gatsby";
import React from "react";
import ScrollTopButton from "../ScrollTopButton";
import SocialMediaLinks from "../SocialMediaLinks";
import * as styles from "./style.module.scss";
import logo from "/static/logo.svg";

const Footer = () => {

	return (
		<footer className={styles.footer}>

			<div className={styles.footer__inner}>
				<div className={styles.inner__left}>
					<div className={styles.logo}>
						<Link to="/">
							<img src={logo} width="188" height="53" alt="Language Partners"/>
						</Link>
					</div>	
					<SocialMediaLinks/>
				</div>

				<div className={styles.inner__center}>
					<ul className={styles.links}>
						<li><span className={styles.links__item}>伊島校</span>
							<ul>
								<li><Link className={styles.links__childItem} to="/school/ishima/">英会話学童</Link></li>
							</ul>
						</li>
						<li><span className={styles.links__item}>平田校</span>
							<ul>
								<li><Link className={styles.links__childItem} to="/school/hirata/">英会話教室</Link></li>
							</ul>
						</li>
					</ul>
					<ul className={styles.links}>
						<li><span className={styles.links__item}>スタッフ紹介</span>
							<ul>
								<li><Link className={styles.links__childItem} to="/staff/ishima/">英会話学童 伊島校</Link></li>
								<li><Link className={styles.links__childItem} to="/staff/hirata/">英会話教室 平田校</Link></li>
							</ul>
						</li>
						<li><span className={styles.links__item}>よくある質問</span>
							<ul>
								<li><Link className={styles.links__childItem} to="/qa/ishima/">英会話学童 伊島校</Link></li>
								<li><Link className={styles.links__childItem} to="/qa/hirata/">英会話教室 平田校</Link></li>
							</ul>
						</li>
					</ul>
				</div>

				<div className={styles.inner__right}>
					<ul className={styles.links}>
						<li><Link className={styles.links__item} to="/policy/">サイトポリシー</Link></li>
						<li><Link className={styles.links__item} to="/outline/">会社概要</Link></li>
						<li><Link className={styles.links__item} to="/clause/">ランゲージパートナーズ約款</Link></li>
						<li><Link className={styles.links__item} to="/privacy-ortustech/">個人情報直接収集に関するお知らせ</Link></li>
					</ul>
					<ul className={styles.links}>
						<li><Link className={styles.links__item} to="/contact/trial-lesson/">体験レッスンお申し込み</Link></li>
						<li><Link className={styles.links__item} to="/contact/">お問い合わせ</Link></li>
					</ul>
				</div>
			</div>

			<p className={styles.copy}>©2009-{new Date().getFullYear()} Language Partners. All rights reserved.</p>
			<ScrollTopButton />

		</footer>
	);
};

export default Footer;