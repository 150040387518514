import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import * as styles from "./style.module.scss";

const Layout = ({ children }) => {
	return (
		<div className={styles.layout}>
			<Header />
			<main className={styles.body}>
				{children}
			</main>
			<Footer />
		</div>
	);
};

export default Layout;