// extracted by mini-css-extract-plugin
export var copy = "style-module--copy--c810d";
export var footer = "style-module--footer--cc446";
export var footer__inner = "style-module--footer__inner--0627a";
export var inner__center = "style-module--inner__center--e2746";
export var inner__left = "style-module--inner__left--face3";
export var inner__right = "style-module--inner__right--2c7f3";
export var links = "style-module--links--18d49";
export var links__childItem = "style-module--links__childItem--de10b";
export var links__item = "style-module--links__item--c2079";
export var logo = "style-module--logo--86c63";