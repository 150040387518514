import React from "react";
import Layout from "./src/components/Layout";
import "./src/styles/style.scss";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const wrapRootElement = ({ element }) => {
	const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY || "";
	return (
		<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
			{element}
		</GoogleReCaptchaProvider>
	);
};

export const wrapPageElement = ({ element }) => {
	return <Layout>{element}</Layout>
};

export const onClientEntry = () => {
	const script = document.createElement('script');
	script.innerHTML = `
	(function(d) {
		var config = {
			kitId: 'qjl3kho',
			scriptTimeout: 3000,
			async: true
		},
		h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
	})(document);`;
	document.head.appendChild(script);
};