exports.components = {
  "component---src-components-template-blog-js": () => import("./../../../src/components/Template/Blog.js" /* webpackChunkName: "component---src-components-template-blog-js" */),
  "component---src-components-template-blog-list-js": () => import("./../../../src/components/Template/BlogList.js" /* webpackChunkName: "component---src-components-template-blog-list-js" */),
  "component---src-components-template-terms-js": () => import("./../../../src/components/Template/Terms.js" /* webpackChunkName: "component---src-components-template-terms-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-hirata-js": () => import("./../../../src/pages/access/hirata.js" /* webpackChunkName: "component---src-pages-access-hirata-js" */),
  "component---src-pages-access-ishima-js": () => import("./../../../src/pages/access/ishima.js" /* webpackChunkName: "component---src-pages-access-ishima-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-preview-js": () => import("./../../../src/pages/blog/preview.js" /* webpackChunkName: "component---src-pages-blog-preview-js" */),
  "component---src-pages-contact-event-js": () => import("./../../../src/pages/contact/event.js" /* webpackChunkName: "component---src-pages-contact-event-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-special-lesson-js": () => import("./../../../src/pages/contact/special-lesson.js" /* webpackChunkName: "component---src-pages-contact-special-lesson-js" */),
  "component---src-pages-contact-trial-lesson-js": () => import("./../../../src/pages/contact/trial-lesson.js" /* webpackChunkName: "component---src-pages-contact-trial-lesson-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-phonics-js": () => import("./../../../src/pages/phonics.js" /* webpackChunkName: "component---src-pages-phonics-js" */),
  "component---src-pages-qa-hirata-js": () => import("./../../../src/pages/qa/hirata.js" /* webpackChunkName: "component---src-pages-qa-hirata-js" */),
  "component---src-pages-qa-ishima-js": () => import("./../../../src/pages/qa/ishima.js" /* webpackChunkName: "component---src-pages-qa-ishima-js" */),
  "component---src-pages-school-hirata-js": () => import("./../../../src/pages/school/hirata.js" /* webpackChunkName: "component---src-pages-school-hirata-js" */),
  "component---src-pages-school-ishima-js": () => import("./../../../src/pages/school/ishima.js" /* webpackChunkName: "component---src-pages-school-ishima-js" */),
  "component---src-pages-staff-hirata-js": () => import("./../../../src/pages/staff/hirata.js" /* webpackChunkName: "component---src-pages-staff-hirata-js" */),
  "component---src-pages-staff-index-js": () => import("./../../../src/pages/staff/index.js" /* webpackChunkName: "component---src-pages-staff-index-js" */),
  "component---src-pages-staff-ishima-js": () => import("./../../../src/pages/staff/ishima.js" /* webpackChunkName: "component---src-pages-staff-ishima-js" */)
}

