import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import useMediaQueryWidth from "../../hooks/useMediaQueryWidth";
import FormLinkButtons from "../FormLinkButtons";
import SocialMediaLinks from "../SocialMediaLinks";
import * as styles from "./style.module.scss";
import access from "/static/icon_access.svg";
import blog from "/static/icon_blog.svg";
import phonics from "/static/icon_phonics.svg";
import school from "/static/icon_school.svg";
import sensei from "/static/icon_sensei.svg";
import shitumon from "/static/icon_shitumon.svg";
import logo from "/static/logo.svg";
import telephone from "/static/parts_telephone-tantai.svg";
import tel from "/static/parts_telephone.svg";

const Header = () => {

	const isPc = useMediaQueryWidth();
	const isMobile = !isPc;

	const location = useLocation();
	const pagePath = `${location.pathname.replace(/\/$/, '')}/`;
	const isContactPage = /^\/contact\//.test(pagePath);
	
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(prev => ! prev);
	};

	const closeMenu = () => {
		setIsMenuOpen(false);
	};

	useEffect(() => {
		setIsMenuOpen(false);	
		document.querySelectorAll(`.${styles.listItem}`).forEach((item) => {
			item.classList.remove(styles.active);
		});
	}, [isPc]);

	useEffect(() => {
		document.querySelectorAll(`.${styles.listItem}`).forEach((item) => {
			item.classList.remove(styles.active);
		});

		if (isMenuOpen) {
			document.body.classList.add("isMenuOpen");
			document.querySelector(`.${styles.menu}`).scrollTo(0, 0);
		} else {
			document.body.classList.remove("isMenuOpen");
		}
	}, [isMenuOpen]);


	const listItemMenuFocus = (e) => {
		if (isPc) {
			e.currentTarget.parentNode.classList.add(styles.active);
		}

		const children = e.currentTarget?.nextElementSibling?.children;

		if (children) {
			Array.from(children).forEach(item => {
				item.children[0].tabIndex = "auto";
			});
		}
	}

	const listItemMenuBlur = (e) => {
		if (isPc) {
			e.currentTarget.parentNode.classList.remove(styles.active);
		}
	}

	const activeChildMenu = (e) => {
		const listItem = e.currentTarget.parentNode.parentNode.parentNode;
		listItem.classList.add(styles.active);

		const childMenu = Array.from(e.currentTarget.parentNode.parentNode.children);
		childMenu.forEach(item => {
			item.children[0].tabIndex = "auto";
		});
	}

	const closeChildMenu = (e) => {
		const listItem = e.currentTarget.parentNode.parentNode.parentNode;
		listItem.classList.remove(styles.active);

		const childMenu = Array.from(e.currentTarget.parentNode.parentNode.children);
		childMenu.forEach(item => {
			item.children[0].tabIndex = -1;
		});
	}

	const childMenuItemClick = (e) => {
		if (isMobile) {
			setIsMenuOpen(false);
		}

		e.currentTarget.blur();
	}

	const itemMenuButtonClick = (e) => {

		if (isPc) {
			if (e.detail !== 0) {
				e.currentTarget.blur();
			}
		} 
		else {
			const listItem = e.currentTarget.parentNode;
			if (listItem.classList.contains(styles.active)) {
				listItem.classList.remove(styles.active);
			} else {
				listItem.classList.add(styles.active);
			}
		}
	}

	return (
		<header className={styles.header}>

		{!isContactPage && 
			<FormLinkButtons/>
		}
		{isMobile &&
			<div className={styles.showMobile}>
				<div className={styles.top}>
					<div className={styles.logo}>
						<Link to="/">
							<img src={logo} width="188" height="53" alt="Language Partners"/>
						</Link>
					</div>
					<button className={[styles.menuBtn, isMenuOpen && styles.menuBtn__open].join(" ")} type="button" aria-label="メニュー開閉ボタン" onClick={toggleMenu}>
						<span></span>
						<span></span>
						<span></span>
					</button>
				</div>
			</div>
		}

		<div className={[styles.menu, isMenuOpen && styles.menu__isOpen].join(" ")}>

			<div className={styles.top}>
				<div className={styles.logo}>
					<Link to="/">
						<img src={logo} width="188" height="53" alt="Language Partners"/>
					</Link>
				</div>

				<div className={styles.top__right}>
					<img src={tel} width="218" height="65" alt="日本人スタッフ対応。繋がらない場合は、お掛け直し致します。086-251-0605。受付時間 10:00-19:00"/>
					<SocialMediaLinks />
				</div>
			</div>

				<nav>
					<ul className={styles.navList}>

						{isMobile &&
						<li className={[styles.listItem, styles.showMobile].join(" ")}>
							<Link className={styles.listItem__menu} to="/" onClick={closeMenu}>
								<span>TOP</span>
							</Link>
						</li>
						}

						<li className={styles.listItem}>
							<button className={styles.listItem__menu} onFocus={listItemMenuFocus} onBlur={listItemMenuBlur} onClick={itemMenuButtonClick}>
								<img src={school} width="48" height="48" alt=""/>
								<span>各校紹介</span>
							</button>
							<ul className={styles.listItem__childMenu}>
								<li className={styles.childMenu__item}>
									<Link to="/school/ishima/" tabIndex="-1" onFocus={activeChildMenu} onBlur={closeChildMenu} onClick={childMenuItemClick}><p>英会話学童 伊島校</p></Link>
								</li>
								<li className={styles.childMenu__item}>
									<Link to="/school/hirata/" tabIndex="-1" onFocus={activeChildMenu} onBlur={closeChildMenu} onClick={childMenuItemClick}>英会話教室 平田校</Link>
								</li>
							</ul>
						</li>

						<li className={styles.listItem}>
							<Link className={styles.listItem__menu} to="/blog/" onClick={closeMenu}>
								<img src={blog} width="48" height="48" alt=""/>
								<span>お知らせ・ブログ</span>
							</Link>
						</li>

						<li className={styles.listItem}>
							<Link className={styles.listItem__menu} to="/phonics/" onClick={closeMenu}>
								<img src={phonics} width="48" height="48" alt=""/>
								<span>フォニックス</span>
							</Link>
						</li>

						<li className={styles.listItem}>
							<button className={styles.listItem__menu} onFocus={listItemMenuFocus} onBlur={listItemMenuBlur} onClick={itemMenuButtonClick}>
								<img src={sensei} width="48" height="48" alt=""/>
								<span>スタッフ紹介</span>
							</button>
							<ul className={styles.listItem__childMenu}>
								<li className={styles.childMenu__item}>
									<Link to="/staff/ishima/" tabIndex="-1" onFocus={activeChildMenu} onBlur={closeChildMenu} onClick={childMenuItemClick}>英会話学童 伊島校</Link>
								</li>
								<li className={styles.childMenu__item}>
									<Link to="/staff/hirata/" tabIndex="-1" onFocus={activeChildMenu} onBlur={closeChildMenu} onClick={childMenuItemClick}>英会話教室 平田校</Link>
								</li>
							</ul>
						</li>

						<li className={styles.listItem}>
							<button className={styles.listItem__menu} onFocus={listItemMenuFocus} onBlur={listItemMenuBlur} onClick={itemMenuButtonClick}>
								<img src={shitumon} width="48" height="48" alt=""/>
								<span>よくある質問</span>
							</button>
							<ul className={styles.listItem__childMenu}>
								<li className={styles.childMenu__item}>
									<Link to="/qa/ishima/" tabIndex="-1" onFocus={activeChildMenu} onBlur={closeChildMenu} onClick={childMenuItemClick}>英会話学童 伊島校</Link>
								</li>
								<li className={styles.childMenu__item}>
									<Link to="/qa/hirata/" tabIndex="-1" onFocus={activeChildMenu} onBlur={closeChildMenu} onClick={childMenuItemClick}>英会話教室 平田校</Link>
								</li>
							</ul>
						</li>

						<li className={styles.listItem}>
							<button className={styles.listItem__menu} onFocus={listItemMenuFocus} onBlur={listItemMenuBlur} onClick={itemMenuButtonClick}>
								<img src={access} width="48" height="48" alt=""/>
								<span>アクセス</span>
							</button>
							<ul className={styles.listItem__childMenu}>
								<li className={styles.childMenu__item}>
									<Link to="/access/ishima/" tabIndex="-1" onFocus={activeChildMenu} onBlur={closeChildMenu} onClick={childMenuItemClick}>英会話学童 伊島校</Link>
								</li>
								<li className={styles.childMenu__item}>
									<Link to="/access/hirata/" tabIndex="-1" onFocus={activeChildMenu} onBlur={closeChildMenu} onClick={childMenuItemClick}>英会話教室 平田校</Link>
								</li>
							</ul>
						</li>

					</ul>
				</nav>
				{isMobile &&
				<>
				<div className={styles.mobileHeaderContents}>
					<FormLinkButtons unfixed={true} onClick={closeMenu}/>
					<a href="tel:0862510605" className={styles.telBtn}>
						<img src={telephone} width="27" height="27" alt=""/>
						<div>
							<p>お電話でのお問い合わせ</p>
							<p className={styles.time}>受付時間 10:00 - 19:00</p>
						</div>
					</a>
					<p className={styles.sub}>
						<span>【お電話でのお問い合わせについて】</span>
						日本人スタッフが対応いたします。<br/>10:00〜14:00は比較的つながりやすい時間です。<br/>繋がらない場合は、お掛け直し致します。
					</p>

					<SocialMediaLinks/>

					<div className={styles.links}>
						<p className={styles.link}><Link to="/policy/" onClick={closeMenu}>サイトポリシー</Link></p>
						<p className={styles.link}><Link to="/outline/" onClick={closeMenu}>会社概要</Link></p>
						<p className={styles.link}><Link to="/clause/" onClick={closeMenu}>ランゲージパートナーズ約款</Link></p>
						<p className={styles.link}><Link to="/privacy-ortustech/" onClick={closeMenu}>個人情報直接収集に関するお知らせ</Link></p>
					</div>
					<p className={styles.copy}>©2009-{new Date().getFullYear()} Language Partners. All rights reserved.</p>
				</div>
				</>
				}
			</div>
		</header>
	);
};

export default Header;