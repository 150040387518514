import { useCallback, useEffect, useState } from "react";
import { isBrowser } from "../libraries";

/**
 * 
 * @param {number} minWidth 最小画面幅（px）
 * @returns {boolean} minWidth以上であればtrue
 */
const useMediaQueryWidth = (minWidth = 1025) => {
	const [mediaQuery, setMediaQuery] = useState(null);
	const [isWide, setIsWide] = useState(null);

	const handleChangeWidth = useCallback((e) => {
		setIsWide(e.matches);
	}, [setIsWide]);

	useEffect(() => {
		if (! isBrowser()) {
			return;
		}

		setMediaQuery(window.matchMedia(`(min-width: ${minWidth}px)`));
	}, [setMediaQuery, minWidth]);

	useEffect(() => {
		if (! mediaQuery) {
			return;
		}

		setIsWide(mediaQuery.matches);
	}, [setIsWide, mediaQuery]);

	useEffect(() => {
		if (! mediaQuery) {
			return;
		}

		mediaQuery.addEventListener("change", handleChangeWidth);
		return () => mediaQuery.removeEventListener("change", handleChangeWidth);
	}, [mediaQuery, handleChangeWidth]);

	return isWide;
};

export default useMediaQueryWidth; 