import React from "react";
import * as styles from "./style.module.scss";
import facebook from "/static/icon_facebook.svg";
import instagram from "/static/icon_instagram.svg";
import youtube from "/static/icon_youtube.svg";
// import twitter from "/static/icon_twitter.svg"; TODO: アカウント未作成

const SocialMediaLinks = () => {
	return (
	<div className={styles.sns}>
		<a href="https://www.instagram.com/language_partners.okayama/" title="Instagram">
			<img src={instagram} width="27" height="27" alt="Instagram"/>
		</a>
		{/* TODO: アカウントを作成してから表示する */}
		{/* <a href="/TODO: " title="Twitter">
			<img src={twitter} width="27" height="27" alt="Twitter"/>
		</a> */}
		<a href="https://www.facebook.com/languagepartners.okayama/" title="Facebook">
			<img src={facebook} width="27" height="27" alt="Facebook"/>
		</a>
		<a href="https://www.youtube.com/channel/UCJMs5vgLmH9IMT86sleWB2g" title="YouTube">
			<img src={youtube} alt="YouTube" />
		</a>
	</div>
	);
};

export default SocialMediaLinks;