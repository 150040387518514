import React, { memo } from "react";
import { scroll } from "../../libraries";
import toTopImage from "./icon_totop.svg";
import * as styles from "./style.module.scss";

const ScrollTopButton = () => {
	const scrollToTop = () => {
		const DURATION = 300;
		const from = window.pageYOffset;
		const to = 0;
		const startTime = performance.now();
		scroll(from, to, startTime, DURATION);
	};

	return (
		<button className={styles.button} type="button" onClick={scrollToTop}>
			<img src={toTopImage} alt="" width={117} height={100} />
			<span className={styles.text}>TOPへ</span>
		</button>
	);
};

export default memo(ScrollTopButton);